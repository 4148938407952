<template>
  <div class="app-box">
    <img
      class="imgBox"
      src="https://wwyt.eco-v.cn/merchant/img/login-logo.ddf7bdb.png"
      alt=""
    />
    <h3 class="titleb">欢迎来到招商系统</h3>
    <div class="bottombae">
      <van-button type="default" class="mamrit" hairline size="large" @click="getWx" v-if="IswechatShow"
        >微信登录</van-button
      >
      <van-button type="default" class="mamrit" hairline size="large" @click="Jump"
        >密码登录</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      IswechatShow: true,
    };
  },
  created(){
      this.Iswechat();
  },
  methods: {
    Jump() {
      this.$router.push({
        path: "/Webpage",
        query: { redirect: this.$route.query.redirect },
      });
    },
    // 判断微信
    Iswechat() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.IswechatShow = true;
      } else {
        this.IswechatShow = false;
      }
    },
    getWx(){
       window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8eae196271529d78&redirect_uri=https%3A%2F%2Fcrmt.wwy-x.com%2F%23%2FWeWacetOath&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect" 
    }
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-image: url("../../assets/img/imglogin.jpg");
  background-size: 100% 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.titleb {
  text-align: center;
  font-size: .85rem;
  margin-top: -35px;
  color: #fff;
}
.imgBox {
  width: 3rem;
    height: 4.8rem;
    margin: auto;
    margin-top: 2.2rem;
}
.app-box ::v-deep.van-button {
  background: none;
  color: #fff;
  border-radius: 10px;
}
.bottombae {
  width: 70%;
  margin: auto;
  margin-top: 3rem;
}
.mamrit{
    margin-bottom: .6rem;
}
</style>